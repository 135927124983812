import { postAPICall } from "../helpers/httpHelper";
import { db } from "../utils/db";
import {
  paginationHelper,
  getPaginationResponse,
} from "../utils/paginationHelper";

import store from "@/store";

//delete entity by id
export const deleteCustomDashboard = async (customDashboardId) => {
  let appUsingMode = store.getters["auth/getAppMode"];
  if(!appUsingMode){
    return
  }
  const [selectedCustomDashboard] = await db.customDashboard
    .where("_id")
    .equals(customDashboardId)
    .primaryKeys();
  if (selectedCustomDashboard) {
    await db.customDashboard.delete(selectedCustomDashboard);
  }
};

//update single entity
export const updateCustomDashboardData = async (customDashboardId, data) => {
  let appUsingMode = store.getters["auth/getAppMode"];
  if(!appUsingMode){
    return
  }
  if (data?.id) {
    delete data.id;
  }
  const selectedCustomDashboard = await fetchDataCustomdashboardIdFromLocal(
    customDashboardId
  );
  if (selectedCustomDashboard) {
    await db.customDashboard.delete(selectedCustomDashboard);
    await addSingleCustomDashboardToLocal(data);
  } else {
    await addSingleCustomDashboardToLocal(data);
  }
};

export const fetchDataCustomdashboardIdFromLocal = async (id) => {
  const [selectedEntityCustomization] = await db.customDashboard
    .where("_id")
    .equals(id)
    .primaryKeys();
  return selectedEntityCustomization;
};

export const addSingleCustomDashboardToLocal = async (templateData) => {
  let appUsingMode = store.getters["auth/getAppMode"];
  if(!appUsingMode){
    return
  }
  return db.customDashboard.add({
    ...templateData,
    ...{ createdAt: new Date().toISOString() },
  });
};

export const updateAssignedToCustomdashboard = async (
  customDashboardId,
  data
) => {
  let appUsingMode = store.getters["auth/getAppMode"];
  if(!appUsingMode){
    return
  }
  const selectedCustomDashboard = await fetchDataCustomdashboardIdFromLocal(
    customDashboardId
  );
  if (selectedCustomDashboard) {
    await db.customDashboard.bulkUpdate([
      {
        key: selectedCustomDashboard,
        changes: {
          ...data,
        },
      },
    ]);
  }
};

export const fetchAssignedCustomDashboards = async (id) => {
  let customDashboards;
  customDashboards = await fetchAssignedCustomDashboardsFromLocal(id);
  if (!customDashboards.length) {
    customDashboards = await fetchAssignedCustomDashboardsFromDatabase(id);
  }
  return customDashboards;
};

export const fetchAssignedCustomDashboardsFromLocal = async (id) => {
  return await db.customDashboard.where({ assigned_to: id }).toArray();
};
export const fetchAssignedCustomDashboardsFromDatabase = async (id) => {
  const result = await postAPICall("GET", `/custom-dashboard/user-type/${id}`);
  return result?.data || [];
};

//Fetching customDashboards
export const fetchCustomDashboardByPagination = async (params) => {
  let customDashboards;
  customDashboards = await fetchCustomDashboardFromLocal(params);
  if (!customDashboards) {
    customDashboards = await fetchCustomDashboardFromDatabase(params);
  }
  return customDashboards;
};

export const fetchCustomDashboardFromLocal = async (params) => {
  let collection = db.customDashboard;
  if (params.order_by) {
    collection = collection.orderBy(params.order_by);
    if (params.order_type === "desc") {
      collection = collection.reverse();
    }
  } else {
    collection = collection.orderBy("updated_at");
    collection = collection.reverse();
  }
  const regex = new RegExp(params.search_string || "", "i");

  if (params.existed_entity_ids) {
    collection = collection.filter((entity) => {
      if (params?.existed_entity_ids?.length) {
        return (
          params.existed_entity_ids.includes(entity._id) &&
          (!params.search_string || regex.test(entity.name))
        );
      }
      return true;
    });
  }

  if (params.search_string) {
    const regex = new RegExp(params.search_string, "i");
    collection = collection.filter((cd) => regex.test(cd.name));
  }

  const count = await collection.count();
  if (params.limit) {
    params.skip = (params.page - 1) * params.limit;
  }
  collection = paginationHelper(collection, params);
  const results = await collection.toArray();
  if (!results || !results.length) {
    return null;
  }
  return getPaginationResponse({
    page: params.page || 1,
    count,
    limit: params.limit || 10,
    skip: params.skip,
    data: results,
    data_field: "data",
    message: "Customdashboards fetched successfully.",
  });
};
export const fetchCustomDashboardFromDatabase = async (params) => {
  return postAPICall("GET", `/custom-dashboard`, params);
};
